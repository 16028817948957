/* eslint linebreak-style: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { ItemStatusWithContacts } from '../../ItemStatus';
import DraggableTooltip from '../draggableTooltip';
import { ItemStatusModes } from '../../ItemStatus/constants';

/**
 * Тултип для мерджа объектов
 * @param {Object} props
 * @param {string} props.id идентификатор тултипа (для отслеживания триггера)
 * @param {Function} props.useStores
 * @param {Object} props.store
 * @param {Function} props.afterShow функция, которая выполнится после того, как тултип появится
 * @param {Function} props.afterHide функция, которая выполнится после того, как тултип скроется
 */
const EditObjectsTooltip = ({
    id,
    store,
    useStores,
    afterShow,
    afterHide,
}) => {
    const getContent = (itemId) => {
        if (!itemId) {
            return null;
        }
        const item = store.getItem(itemId);
        if (!item) {
            return null;
        }

        return (
            <ItemStatusWithContacts
                item={item}
                mode={ItemStatusModes.Edit}
                useStores={useStores}
            />
        );
    };

    return (
        <DraggableTooltip
            id={id}
            getContent={getContent}
            afterShow={afterShow}
            afterHide={afterHide}
        />
    );
};

EditObjectsTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    store: PropTypes.shape({
        getItem: PropTypes.func,
    }).isRequired,
    useStores: PropTypes.func.isRequired,
    afterShow: PropTypes.func,
    afterHide: PropTypes.func,
};

EditObjectsTooltip.defaultProps = {
    afterShow: () => {},
    afterHide: () => {},
};

export default EditObjectsTooltip;
